/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {Grid, Icon, Paper} from "@mui/material";

import "./TopCard.styles.scss";

const TopCard = (props: any) => {
  const {title, count, color, icon, customClickEvent, column} = props;

  return (
    <Grid item sm={column || 4} xs={12} className="top-card">
      <Paper
        onClick={customClickEvent}
        elevation={3}
        sx={{marginTop: "8px", cursor: "pointer"}}
        className={color ? color : "default-color"}>
        <Grid container spacing={2}>
          <Grid item container xs={12} className="icon-total">
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Icon>{icon ? icon : "question"}</Icon>
            </Grid>
            <Grid item xs={8} className="count">
              {count || 0}
            </Grid>
          </Grid>

          <Grid
            className="title"
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "8px",
              fontSize: "0.875rem",
              paddingBottom: "8px",
            }}>
            {title}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default TopCard;
