import { lazy } from "react";
import PrivateLayout from "./layouts/Private";
import PublicLayout from "./layouts/Public";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => {
      window.location.href = "/login";
      return null;
    },
  },

  {
    path: "/send-files/:userID/:processID/:processNumber/:token",
    component: PublicLayout,
    routes: [
      {
        path: "/send-files/:userID/:processID/:processNumber/:token",
        exact: true,
        component: lazy(
          () =>
            import(
              "./views/OnlineRelease/components/DocumentsRelease/components/MobileFilesRelease"
            ),
        ),
      },
    ],
  },
  {
    path: "/send-hbl/:userID/:processID/:processNumber/:token",
    component: PublicLayout,
    routes: [
      {
        path: "/send-hbl/:userID/:processID/:processNumber/:token",
        exact: true,
        component: lazy(() => import("./components/MobileFilesRelease")),
      },
    ],
  },
  {
    path: "/login",
    component: PublicLayout,
    routes: [
      {
        path: "/login",
        exact: true,
        component: lazy(() => import("./views/Login")),
      },
    ],
  },

  {
    route: "*",
    component: PrivateLayout,
    routes: [
      {
        path: "/dashboards",
        exact: true,
        component: lazy(() => import("./views/Dashboards")),
      },
      {
        path: "/chat",
        exact: true,
        component: lazy(() => import("./views/Chat")),
      },
      {
        path: "/online",
        exact: true,
        component: lazy(() => import("./views/Documents")),
      },
      {
        path: "/pending-orders",
        exact: true,
        component: lazy(() => import("./views/Order")),
      },
      {
        path: "/processed-orders",
        exact: true,
        component: lazy(() => import("./views/ProcessedOrder")),
      },
      {
        path: "/financial",
        exact: true,
        component: lazy(() => import("./views/Financial")),
      },
      {
        path: "/companies",
        exact: true,
        component: lazy(() => import("./views/Companies")),
      },
      {
        path: "/user-details/",
        exact: true,
        component: lazy(() => import("./views/UserProfile")),
      },
      {
        path: "/user-details/:id",
        exact: true,
        component: lazy(() => import("./views/UserProfile")),
      },
      {
        path: "/user-validation",
        exact: true,
        component: lazy(() => import("./views/UserDocuments")),
      },
      {
        path: "/search",
        exact: true,
        component: lazy(() => import("./views/Search")),
      },
      {
        path: "/process-details/:id",
        exact: true,
        component: lazy(() => import("./views/ProcessDetails")),
      },
      {
        path: "/company-details/:id",
        exact: true,
        component: lazy(() => import("./views/Companies/CompanyDetails")),
      },
      {
        path: "/users/:companyId",
        exact: true,
        component: lazy(() => import("./views/Users")),
      },
      {
        path: "/branches/:companyId",
        exact: true,
        component: lazy(() => import("./views/Branches")),
      },
      {
        path: "/brokers/:companyId",
        exact: true,
        component: lazy(() => import("./views/Brokers")),
      },
      {
        path: "/bills-to-received",
        exact: true,
        component: lazy(() => import("./views/BillsToReceived")),
      },
      {
        path: "/online-release/",
        exact: true,
        component: lazy(() => import("./views/OnlineRelease")),
      },
      {
        path: "/online-release/:id",
        exact: true,
        component: lazy(() => import("./views/OnlineRelease")),
      },
      {
        path: "/reports",
        exact: true,
        component: lazy(() => import("./views/Reports")),
      },
      {
        path: "/users-overview",
        exact: true,
        component: lazy(() => import("./views/UsersOverview")),
      },
      {
        path: "/pending-hbl",
        exact: true,
        component: lazy(() => import("./views/PendingHbl")),
      },
      {
        path: "/received-courier",
        exact: true,
        component: lazy(
          () => import("./views/Courier/components/ReceivedCourier"),
        ),
      },
      {
        path: "/add-courier",
        exact: true,
        component: lazy(() => import("./views/Courier/components/AddCourier")),
      },
      {
        path: "/monitor-courier",
        exact: true,
        component: lazy(
          () => import("./views/Courier/components/MonitorCourier"),
        ),
      },
      {
        path: "/deconsolidation",
        exact: true,
        component: lazy(() => import("./views/Deconsolidation")),
      },
      {
        path: "/admin-panel",
        exact: true,
        component: lazy(() => import("./views/Panel")),
      },
    ],
  },
];

export default routes;
