import {createActions, createReducer} from "reduxsauce";

type initial = {
  open: boolean;
  anchor: string;
};

const INITIAL_STATE: initial = {
  open: false,
  anchor: "right",
};

export const {Types, Creators} = createActions({
  openNotify: ["open"],
});

const openNotify = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  open: action.open,
});

export default createReducer(INITIAL_STATE, {
  [Types.OPEN_NOTIFY]: openNotify,
});
