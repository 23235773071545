import {createActions, createReducer} from "reduxsauce";

type initial = {
  currentTheme: string;
};

const INITIAL_STATE: initial = {
  currentTheme: "light",
};

export const {Types, Creators} = createActions({
  SetTheme: ["currentTheme"],
});

const SetTheme = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  currentTheme: action.currentTheme,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_THEME]: SetTheme,
});
