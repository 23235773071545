import {createActions, createReducer} from "reduxsauce";

type initial = {
  open: boolean;
  message: string;
  severity: string;
};

const INITIAL_STATE: initial = {
  open: false,
  message: "",
  severity: "info",
};

export const {Types, Creators} = createActions({
  showToast: ["open", "message", "severity"],
  hiddenToast: ["open"],
});

const showToast = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  open: action.open,
  message: action.message,
  severity: action.severity,
});

const hiddenToast = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  open: action.open,
});

export default createReducer(INITIAL_STATE, {
  [Types.SHOW_TOAST]: showToast,
  [Types.HIDDEN_TOAST]: hiddenToast,
});
