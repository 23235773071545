/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect} from "react";
import {Snackbar, Slide, SlideProps} from "@mui/material";
import Alert from "@mui/material/Alert";
import {useDispatch, useSelector} from "react-redux";
import {Types as toastTypes} from "store/reducers/toastReducer";

type TransitionProps = Omit<SlideProps, "direction">;

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const MyToast = () => {
  const dispatch = useDispatch();

  const {open, message, severity} = useSelector(
    (state: any) => state.stateToast,
  );

  const [transition, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);

  const handleClick =
    (Transition: React.ComponentType<TransitionProps>) => () => {
      setTransition(() => Transition);
    };

  useEffect(() => {
    if (open) {
      handleClick(SlideTransition);
    }
  }, [open]);

  const handleClose = () => {
    dispatch({
      type: toastTypes.HIDDEN_TOAST,
      open: false,
    });
  };

  return (
    <Snackbar
      anchorOrigin={{vertical: "top", horizontal: "right"}}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      TransitionComponent={transition}>
      <Alert
        onClose={handleClose}
        severity={severity}
        variant="filled"
        sx={{width: "100%", display: "flex", alignItems: "center"}}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MyToast;
