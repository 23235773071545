/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import {RetrieveUserInformation} from "./User/user.service";

export const newDataAPI = axios.create({
  baseURL: process.env.REACT_APP_NEW_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

newDataAPI.interceptors.request.use(async (config) => {
  const token: string | undefined =
    RetrieveUserInformation()?.accessToken || "";

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const buildURL = (
  query: any,
  sort: any,
  page: number | null,
  limit: number,
) => {
  let url = "";
  if (query !== null && query !== undefined && query !== false)
    url = url.concat(`?q=${query}`);
  if (sort !== null && sort !== undefined) url = url.concat(`&sort=${sort}`);
  if (page !== null && page !== undefined) url = url.concat(`&_page=${page}`);
  if (limit !== null && limit !== undefined)
    url = url.concat(query ? `&_pageSize=${limit}` : `?_pageSize=${limit}`);

  return url;
};
