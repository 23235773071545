/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import {useSelector} from "react-redux";

const CustomLoading = () => {
  const {currentTheme} = useSelector((state: any) => state.stateTheme);
  const {isLoading} = useSelector((state: any) => state.stateLoading);
  return (
    <Backdrop open={isLoading || false}>
      <CircularProgress
        sx={{color: currentTheme === "light" ? "#6f2c91" : "#00b2af"}}
      />
    </Backdrop>
  );
};

export default CustomLoading;
