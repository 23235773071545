import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./global/style-guide.scss";

ReactDOM.render(
  <App />,

  document.getElementById("root"),
);

reportWebVitals();
