/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {Suspense, useEffect, useState} from "react";
import {renderRoutes} from "react-router-config";

import {MyDrawer, MyToast, NavBar} from "components";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useSelector, useDispatch} from "react-redux";
import {Types as themeTypes} from "store/reducers/themeReducer";
import {Types as dialogTypes} from "store/reducers/dialogReducer";
import "./private.styles.scss";

import {Grid, Container, Box, Paper, CircularProgress} from "@mui/material";
import {CustomLoading} from "ui";
import {RetrieveUserInformation} from "services/User/user.service";
import TokenVerification from "resources/tokenVerification";
import CustomDialog from "ui/CustomDialog";

const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1536,
      xl: 1920,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#6f2c91",
    },
  },
});

const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1536,
      xl: 1920,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "rgba(0, 178, 175, 1)",
    },
  },
});

const Private = (props: any) => {
  const {route} = props;
  const token = RetrieveUserInformation().accessToken;
  const dispatch = useDispatch();
  const {currentTheme} = useSelector((state: any) => state.stateTheme);
  const [themeUser, setThemeUser] = useState(currentTheme);

  const loadTheme = async () => {
    const theme: string | null = localStorage.getItem("theme");
    if (theme) {
      setThemeUser(JSON.parse(theme));
    }
  };

  const changeTheme = (value: string) => {
    if (value === "dark") {
      dispatch({
        type: themeTypes.SET_THEME,
        currentTheme: themeUser,
      });
    }
  };
  const redirectToLogin = () => {
    return (window.location.href = "/login");
  };
  const checkPermission = () => {
    const myToken = TokenVerification(token);

    if (!myToken) {
      dispatch({
        type: dialogTypes.CHANGE_DIALOG,
        isOpen: true,
        status: "error",
        message: "Sessão expirada",
      });
    }
    return;
  };

  useEffect(() => {
    checkPermission();
  }, []);

  useEffect(() => {
    loadTheme();
  }, []);

  useEffect(() => {
    changeTheme(themeUser);
  }, [themeUser]);

  return (
    <ThemeProvider theme={currentTheme === "light" ? lightTheme : darkTheme}>
      <Container maxWidth="xl" disableGutters>
        <Box sx={{height: "100vh"}}>
          <Grid container>
            <Grid item xs={12} sm={2} className="">
              <NavBar />
            </Grid>
            <Grid item xs={12} sm={10}>
              <Paper
                elevation={6}
                sx={{
                  borderRadius: 0,
                }}
                color="primary">
                <main className="root_main">
                  <Suspense fallback={<CircularProgress color="primary" />}>
                    {renderRoutes(route.routes)}
                  </Suspense>
                  <CustomLoading />
                  <MyDrawer />
                </main>
              </Paper>
            </Grid>
            <MyToast />
          </Grid>
        </Box>
        <CustomDialog customOnClick={() => redirectToLogin()} />
      </Container>
    </ThemeProvider>
  );
};

export default Private;
