/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import PropTypes from "prop-types";

import "./pageTitle.styles.scss";
import {Divider, Box, Typography, Grid, IconButton} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {useHistory} from "react-router-dom";

const PageTitle = ({title, subtitle}) => {
  const history = useHistory();

  return (
    <Box className="page-title-container">
      <Grid container>
        <Grid
          item
          xs="auto"
          onClick={() => history.goBack()}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}>
          <IconButton>
            <ArrowBackIosIcon fontSize="large" color="primary" />
          </IconButton>
        </Grid>
        <Grid item xs={11}>
          <Typography className="page-subtitle">{subtitle}</Typography>
          <Typography
            className="page-title"
            sx={{fontWeight: "bold"}}
            color="primary">
            {title}
          </Typography>
        </Grid>
      </Grid>

      <Divider className="page-title-divider" />
    </Box>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.any,
};

export default PageTitle;
