/* eslint-disable @typescript-eslint/no-explicit-any */
import jwtDecode from "jwt-decode";
const TokenVerification = (token: string) => {
  const decodeJwt: any = jwtDecode(token);

  if (!decodeJwt.exp) {
    return true;
  } else {
    if (new Date(decodeJwt.exp * 1000) > new Date()) {
      return true;
    } else {
      return false;
    }
  }
};
export default TokenVerification;
