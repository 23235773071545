/* eslint-disable @typescript-eslint/no-explicit-any */
import {createActions, createReducer} from "reduxsauce";

type initial = {
  processSelected: any | null;
};

const INITIAL_STATE: initial = {
  processSelected: null,
};

export const {Types, Creators} = createActions({
  setProcessDetails: ["processSelected"],
});

const setProcessDetails = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  processSelected: action.processSelected,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_PROCESS_DETAILS]: setProcessDetails,
});
