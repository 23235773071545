/* eslint-disable @typescript-eslint/no-explicit-any */
import {createActions, createReducer} from "reduxsauce";

type initial = {
  courier: any;
};
const INITIAL_STATE: initial = {
  courier: {
    status: {
      provided: 0,
      delayed: 0,
      divergent: 0,
      finished: 0,
      noCourier: 0,
    },
    list: {
      provided: [],
      delayed: [],
      divergent: [],
      finished: [],
      noCourier: [],
    },
    data: [],
    active: "provided",
  },
};

export const {Types, Creators} = createActions({
  getCourierRequest: ["courier"],
});

const getCourierRequest = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  courier: action.courier,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_COURIER_REQUEST]: getCourierRequest,
});
