/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useSelector} from "react-redux";
import {Container, Box} from "@mui/material";
import {renderRoutes} from "react-router-config";
import CustomDialog from "ui/CustomDialog";

const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1536,
      xl: 1920,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#6f2c91",
    },
  },
});

const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1536,
      xl: 1920,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "rgba(0, 178, 175, 1)",
    },
  },
});

const Public = (props: any) => {
  const {route} = props;
  const {currentTheme} = useSelector((state: any) => state.stateTheme);

  return (
    <ThemeProvider theme={currentTheme === "light" ? lightTheme : darkTheme}>
      <Container maxWidth="xl" disableGutters>
        <Box sx={{height: "100vh"}}>{renderRoutes(route.routes)}</Box>
        <CustomDialog />
      </Container>
    </ThemeProvider>
  );
};

export default Public;
