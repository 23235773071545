/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Types as tabPanelTypes } from "store/reducers/tabPanelReducer";
import inextLogo from "assets/images/new_logo.svg";
import LogoCargoPLay from "assets/images/logo.svg";
import Inext from "assets/images/new_logo.svg";
import LogoCargoPlayDark from "assets/images/logo_black-removebg-preview.png";

import "./navBar.styles.scss";

import { useHistory } from "react-router";

import {
  Grid,
  Box,
  Divider,
  Paper,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import EngineeringIcon from "@mui/icons-material/Engineering";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PaymentIcon from "@mui/icons-material/Payment";
import ArticleIcon from "@mui/icons-material/Article";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DvrIcon from "@mui/icons-material/Dvr";
import { InfoUser } from "components";
import useWindowDimensions from "util/useWindowDimensions";
import { RetrieveUserInformation } from "services/User/user.service";
import PendingActions from "@mui/icons-material/PendingActions";
import GradingIcon from '@mui/icons-material/Grading';
const NavBar = () => {
  const user = RetrieveUserInformation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { currentTheme } = useSelector((state: any) => state.stateTheme);
  const { value } = useSelector((state: any) => state.stateTabPanel);
  const [expanded, setExpanded] = useState("");
  const menu = [
    {
      id: 0,
      name: "DASHBOARD",
      icon: <DashboardIcon />,
      path: "/dashboards",
    },
    {
      name: "GESTÃO",
      icon: <EngineeringIcon />,
      haveChildren: true,
      contain: false,
      expanded: "management",
      subMenu: [
        {
          id: 1,
          name: "EMPRESAS",
          icon: <BusinessIcon />,
          path: "/companies",
          children: true,
        },
        {
          id: 2,
          name: "USUÁRIOS",
          icon: <PeopleAltIcon />,
          path: "/users-overview",
          children: true,
        },
        {
          id: 16,
          name: "PAINEL ADMIN",
          icon: <AdminPanelSettingsIcon />,
          path: "/admin-panel",
          children: true,
        },
      ],
    },
    {
      id: 3,
      name: "PESQUISAR PROCESSO",
      icon: <FindInPageIcon />,
      path: "/search",
    },
    {
      id: 4,
      name: "CHAT",
      icon: <ForumOutlinedIcon />,
      path: "/chat",
    },
    {
      name: "DOCUMENTOS",
      icon: <FileCopyIcon />,
      haveChildren: true,
      contain: true,
      expanded: "document",
      subMenu: [
        {
          name: "LIBERAÇÃO",
          icon: <ArticleIcon />,
          haveChildren: true,
          contain: false,
          expanded: "documentRelease",
          subMenu: [
            {
              id: 5,
              name: "ENVIO DE DOCUMENTOS",
              icon: <CreateNewFolderIcon />,
              path: "/online-release",
              children: true,
            },
            {
              id: 6,
              name: "STATUS DA LIBERAÇÃO",
              icon: <DoneAllIcon />,
              path: "/online",
              children: true,
            },
            {
              id: 7,
              name: "APROVAR PROCURAÇÃO",
              icon: <InsertDriveFileIcon />,
              path: "/user-validation",
              children: true,
            },
            {
              id: 13,
              name: "HBL PENDENTES",
              icon: <AssignmentLateIcon />,
              path: "/pending-hbl",
              children: true,
            },
          ],
        },
        {
          name: "COURIER",
          icon: <TransferWithinAStationIcon />,
          haveChildren: true,
          contain: false,
          expanded: "courier",
          subMenu: [
            {
              id: 19,
              name: "MONITORAMENTO",
              icon: <DvrIcon />,
              path: "/monitor-courier",
            },
            {
              id: 14,
              name: "INCLUSÃO",
              icon: <CreateNewFolderIcon />,
              path: "/add-courier",
            },
            {
              id: 15,
              name: "RECEBIMENTO",
              icon: <SnippetFolderIcon />,
              path: "/received-courier",
            },
          ],
        },
        {
          name: "DESCONSOLIDAÇÃO",
          icon: <TransferWithinAStationIcon />,
          haveChildren: true,
          contain: false,
          expanded: "des",
          subMenu: [
            {
              id: 17,
              name: "tela 1",
              icon: <CreateNewFolderIcon />,
              path: "/deconsolidation",
            },
            {
              id: 18,
              name: "tela 2",
              icon: <SnippetFolderIcon />,
              path: "/received-courier",
            },
          ],
        },
      ],
    },

    {
      name: "PEDIDOS",
      icon: <ReceiptOutlinedIcon />,
      haveChildren: true,
      contain: false,
      expanded: "orders",
      subMenu: [
        {
          id: 8,
          name: "PEDIDOS PENDENTES",
          icon: <PendingActions />,
          path: "/pending-orders",
          children: true,

        },
        {//import GradingIcon from '@mui/icons-material/Grading';
          id: 20,
          name: "PEDIDOS PROCESSADOS",
          icon: <GradingIcon />,
          path: "/processed-orders",
          children: true,
        }
      ]
    },
    {
      name: "FINANCEIRO",
      icon: <AttachMoneyOutlinedIcon />,
      haveChildren: true,
      contain: false,
      expanded: "financial",
      subMenu: [
        {
          id: 9,
          name: "CONTAS A RECEBER",
          icon: <PointOfSaleIcon />,
          path: "/bills-to-received",
          children: true,
        },
        {
          id: 10,
          name: "CONTAS A PAGAR",
          icon: <PaymentIcon />,
          path: "/bills-to-received",
          children: true,
        },
        {
          id: 11,
          name: "FATURAS",
          icon: <RequestQuoteIcon />,
          path: "/faturas",
          children: true,
        },
      ],
    },
    {
      id: 12,
      name: "RELATÓRIOS",
      icon: <FileOpenIcon />,
      path: "/reports",
    },
  ];

  const createMenuRecursive = (item: any, level: number, index: number) => {
    level++;

    return (
      <Fragment key={index}>
        { }
        <ListItemButton
          sx={{
            pl: level + 1,
            display:
              user.roles.includes("Administrator") === false &&
                (item.id === 16 || item.id === 2)
                ? "none"
                : "",
          }}
          onClick={() => {
            item.haveChildren &&
              setExpanded(expanded === item.expanded ? "" : item.expanded);
            !item.haveChildren && history.push(`${item.path}`);
            !item.haveChildren && handlePanel(item.id);
          }}
          className={
            currentTheme === "dark" && value === item.id && !item.haveChildren
              ? "navBar-selected-dark"
              : currentTheme === "light" &&
                value === item.id &&
                !item.haveChildren
                ? "navBar-selected"
                : ""
          }>
          <ListItemIcon
            className={
              currentTheme === "dark" && value === item.id && !item.haveChildren
                ? "navBar-icon-selected-dark"
                : currentTheme === "light" &&
                  value === item.id &&
                  !item.haveChildren
                  ? "navBar-icon-selected"
                  : ""
            }>
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primary={item.name}
            sx={{ marginLeft: "8px" }}
            primaryTypographyProps={{
              fontSize: width <= 1366 ? "0.725rem" : "0.875rem",
            }}
          />
          {!item.haveChildren ? (
            ""
          ) : (expanded === item.expanded && !item.contain) ||
            (expanded !== item.expanded && item.contain) ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )}
        </ListItemButton>
        {item.haveChildren && (
          <Collapse
            in={
              (expanded === item.expanded && !item.contain) ||
              (expanded !== item.expanded && item.contain)
            }
            timeout="auto"
            unmountOnExit>
            {item.haveChildren
              ? item.subMenu.map((sub: any, index: number) =>
                createMenuRecursive(sub, level, index),
              )
              : ""}
          </Collapse>
        )}
      </Fragment>
    );
  };

  const handlePanel = (newValue: number) => {
    localStorage.setItem("tabPanel", newValue.toString());
    dispatch({
      type: tabPanelTypes.ALTER_PANEL,
      value: newValue,
    });
  };

  return (
    <Paper elevation={6} className="navBar-root">
      <Grid container={false}>
        <Grid item xs={12} className="navBar-header">
          <Box
            component="img"
            sx={{
              width: "150px",
              height: "5rem",
            }}
            alt="iNext"
            src={Inext}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider className="navBar-divider" />
        </Grid>
        <Grid item xs={12} className="navBar-content">
          <List sx={{ width: "100%", padding: 0 }} component="nav">
            {menu.map((item: any, index: number) =>
              createMenuRecursive(item, -1, index),
            )}
          </List>
        </Grid>
      </Grid>
      <Box className="navBar-footer">
        <InfoUser />
      </Box>
    </Paper>
  );
};

export default React.memo(NavBar);
