/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {Suspense} from "react";
import {BrowserRouter} from "react-router-dom";
import {renderRoutes} from "react-router-config";
import {Provider as StoreProvider} from "react-redux";
import {CircularProgress} from "@mui/material";
import configureStore from "./store/configureStore";
import routes from "./routes";

const store = configureStore();

function App() {
  return (
    <StoreProvider store={store}>
      <BrowserRouter>
        <Suspense fallback={<CircularProgress color="secondary" />}>
          {renderRoutes(routes)}
        </Suspense>
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;
