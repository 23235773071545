/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Types as openNotifyTypes} from "store/reducers/drawerNotify";
import {Types as chatTypes} from "store/reducers/chatReducer";
import {Types as themeTypes} from "store/reducers/themeReducer";
import {Types as tabPanelTypes} from "store/reducers/tabPanelReducer";

import {useHistory} from "react-router-dom";
import img from "assets/images/avatar.jpg";
import {
  Avatar,
  Grid,
  IconButton,
  Tooltip,
  Badge,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";

import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {RetrieveUserInformation} from "services/User/user.service";
import {getListChat} from "services/Chat/chat.service";

import "./infoUser.styles.scss";

const InfoUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {newMessages} = useSelector((state: any) => state.stateChat);
  const user = RetrieveUserInformation();
  let interval: any;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {currentTheme} = useSelector((state: any) => state.stateTheme);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(
      "theme",
      JSON.stringify(event.target.checked ? "dark" : "light"),
    );
    dispatch({
      type: themeTypes.SET_THEME,
      currentTheme: event.target.checked ? "dark" : "light",
    });
  };

  const handleChangeTheme = (value: string) => {
    localStorage.setItem("theme", JSON.stringify(value));
    dispatch({
      type: themeTypes.SET_THEME,
      currentTheme: value,
    });
  };

  const handleLogout = () => {
    dispatch({
      type: tabPanelTypes.ALTER_PANEL,
      value: 0,
    });
    localStorage.clear();
    history.push("/");
  };

  const getMyListChat = async () => {
    const tempNewMessages: Array<any> = [];
    const tempNoNewMessages: Array<any> = [];
    const reorderChat: Array<any> = [];
    try {
      const response = await getListChat(user._id);

      if (response.data.length > 0) {
        response.data.map((item: any) => {
          if (item.newMessages) {
            tempNewMessages.push(item);
          } else {
            tempNoNewMessages.push(item);
          }
        });
      }

      reorderChat.push(...tempNewMessages, ...tempNoNewMessages);

      // Array.prototype.push.apply(tempNewMessages, tempNoNewMessages);

      // const count = response.data.filter(
      //   (item: any) => item.newMessages > 0,
      // ).length;
      const count = tempNewMessages.length;

      dispatch({
        type: chatTypes.MY_LIST_CHAT,
        newMessages: count,
        data: reorderChat,
      });
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const handleOpenNotify = () => {
    dispatch({
      type: openNotifyTypes.OPEN_NOTIFY,
      open: true,
    });
  };

  useEffect(() => {
    getMyListChat();
  }, []);

  useEffect(() => {
    interval = setInterval(() => {
      getMyListChat();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Grid
      container
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 4,
      }}>
      <Grid item xs={2} sx={{paddingLeft: "2px"}}>
        <Avatar alt="User" src={user.avatar || img} />
      </Grid>
      <Tooltip title={user.name} placement="top" arrow>
        <Grid
          item
          xs={7}
          sx={{
            paddingLeft: "4px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "0.875rem",
            fontWeight: 300,
            marginRight: "8px",
          }}>
          <span>{user.name}</span>
        </Grid>
      </Tooltip>

      <Grid item xs={1}>
        <Tooltip title="Notificações" placement="top" arrow>
          <IconButton size="small" onClick={handleOpenNotify}>
            <Badge badgeContent={newMessages} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item xs={1}>
        <Tooltip title="menu" placement="top" arrow>
          <IconButton size="small" onClick={handleClick}>
            <MoreVertOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}>
          <MenuItem onClick={() => history.push(`/user-details/${user._id}`)}>
            <PersonOutlineIcon fontSize="small" />{" "}
            <span className="infoUser-span">Perfil</span>
          </MenuItem>
          <MenuItem>
            <LightModeIcon
              onClick={() => handleChangeTheme("light")}
              color={currentTheme !== "dark" ? "primary" : "disabled"}
            />
            <Switch
              checked={currentTheme === "dark" ? true : false}
              onChange={handleChange}
              inputProps={{"aria-label": "controlled"}}
            />
            <DarkModeIcon
              onClick={() => handleChangeTheme("dark")}
              color={currentTheme !== "dark" ? "disabled" : "primary"}
            />
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutIcon fontSize="small" />{" "}
            <span className="infoUser-span">Sair</span>
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};
export default InfoUser;
