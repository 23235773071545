import {createActions, createReducer} from "reduxsauce";

type initial = {
  filter: string;
  inactive: boolean;
};

const INITIAL_STATE: initial = {
  filter: "",
  inactive: false,
};

export const {Types, Creators} = createActions({
  getFilter: ["filter", "inactive"],
});

const getFilter = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  filter: action.filter,
  inactive: action.inactive,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_FILTER]: getFilter,
});
