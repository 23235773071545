/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useCallback, useState} from "react";
import {Box, CircularProgress, Button} from "@mui/material";
import {upload} from "services/Upload/upload.service";

import {useDropzone} from "react-dropzone";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {CustomAvatar} from "ui";

import "./uploader.styles.scss";

const Uploader = (props: any) => {
  const {getLink} = props;
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);
    const uploadData = new FormData();
    uploadData.append("image", acceptedFiles[0]);
    await upload(uploadData)
      .then((r) => {
        setLoading(false);

        if (getLink) {
          getLink(r.data.link, acceptedFiles[0]);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("Erro de upload", e);
      });
  }, []);

  const {getRootProps, getInputProps} = useDropzone({onDrop});

  return (
    <Box>
      <div className="uploader">
        {loading ? (
          <div {...getRootProps()} className="drop-progress">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <div {...getRootProps()} className="drop-area">
            <input {...getInputProps()} accept="image/*" capture />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "8px",
              }}>
              <CustomAvatar>
                <CloudUploadIcon />
              </CustomAvatar>
            </Box>
            <p>Arraste e solte seu arquivo aqui</p>
            <p>ou</p>

            <Button
              color="primary"
              variant="contained"
              // className="send-button"
              sx={{minWidth: "150px"}}>
              escolher arquivo
            </Button>
          </div>
        )}
      </div>
    </Box>
  );
};

export default Uploader;
