import {combineReducers} from "redux";

import toastReducer from "./toastReducer";
import tabPanelReducer from "./tabPanelReducer";
import loadingReducer from "./loadingReducer";
import drawerNotify from "./drawerNotify";
import chatReducer from "./chatReducer";
import demurrageReducer from "./demurrageReducer";
import processSelectedReducer from "./processSelectedReducer";
import companyReducer from "./companyReducer";
import themeReducer from "./themeReducer";
import filterReducer from "./filterReducer";
import dialogReducer from "./dialogReducer";
import alertReducer from "./alertReducer";
import loginReducer from "./loginReducer";
import paginationReducer from "./paginationReducer";
import courierReducer from "./courierReducer";
import deconsolidationReducer from "./deconsolidationReducer";
const rootReducer = combineReducers({
  stateToast: toastReducer,
  stateTabPanel: tabPanelReducer,
  stateLoading: loadingReducer,
  stateDrawerNotify: drawerNotify,
  stateChat: chatReducer,
  stateDemurrage: demurrageReducer,
  stateProcessSelected: processSelectedReducer,
  stateCompany: companyReducer,
  stateTheme: themeReducer,
  stateFilter: filterReducer,
  stateDialog: dialogReducer,
  stateAlert: alertReducer,
  stateLogin: loginReducer,
  statePagination: paginationReducer,
  stateCourier: courierReducer,
  stateDeconsolidation: deconsolidationReducer,
});

export default rootReducer;
