/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from "react";
import {Grid, Chip, Divider, Avatar} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment";
import "./mynotifications.scss";
import ChatIcon from "@mui/icons-material/Chat";
import {useHistory} from "react-router-dom";
// import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import {Types as tabPanelTypes} from "store/reducers/tabPanelReducer";
import {Types as chatTypes} from "store/reducers/chatReducer";
import {Types as openNotifyTypes} from "store/reducers/drawerNotify";

const MyNotifications = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {data} = useSelector((state: any) => state.stateChat);
  const [listNotify, setListNotify] = useState<Array<any>>([]);

  const newNotify = () => {
    const temp = data.filter((item: any) => item.newMessages > 0);

    setListNotify(temp);
  };

  const handleOpenChat = (item: any) => {
    history.push("/chat");
    dispatch({
      type: tabPanelTypes.ALTER_PANEL,
      value: 1,
    });

    dispatch({
      type: chatTypes.ALTER_PROCESS_SELECTED,
      processSelected: {_id: item._id, processNumber: item.processNumber},
    });
    const temp = listNotify;
    const idx = temp.findIndex((obj: any) => obj._id === item._id);

    if (idx !== -1) {
      temp.splice(idx, 1);
    }
    setListNotify(temp);

    dispatch({
      type: openNotifyTypes.OPEN_NOTIFY,
    });
  };

  useEffect(() => {
    newNotify();
  }, [data]);

  return (
    <Grid container spacing={2} className="notification-container">
      <Grid item xs={12} className="notification-header">
        <span>Notificações</span>
      </Grid>

      {listNotify.length > 0 ? (
        listNotify.map((item: any, index: number) => (
          <Grid
            item
            container
            xs={12}
            className="notification-item"
            onClick={() => {
              handleOpenChat(item);
            }}
            key={index}>
            <Grid item container xs={12}>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Avatar>
                  <ChatIcon sx={{color: "#575757"}} fontSize="small" />
                </Avatar>
              </Grid>
              <Grid item xs={10} className="notification-item-text">
                <span>{item?.process?.processNumber} </span>
                {" | "}
                <span>{item?.process?.clientReference} </span>
              </Grid>
            </Grid>

            <Grid item xs={12} className="notification-item-content">
              <span>você tem uma nova mensagem...</span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {" "}
                <Chip label="Novo!" color="secondary" size="small" />
              </span>
            </Grid>
            <Grid item xs={12} className="notification-item-time">
              <span>{moment(item?.lastMessageAt).format("HH:mm")}</span>
            </Grid>
            <Divider sx={{width: "100%", marginTop: "4px"}} />
          </Grid>
        ))
      ) : (
        <Grid item xs={12} className="notification-empty">
          Sem notificações
        </Grid>
      )}
    </Grid>
  );
};

export default MyNotifications;
