/* eslint-disable @typescript-eslint/no-explicit-any */
import {Drawer} from "@mui/material";
import {MyNotifications} from "components";
import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {Types as openNotifyTypes} from "store/reducers/drawerNotify";

const MyDrawer = () => {
  const dispatch = useDispatch();
  const {open, anchor} = useSelector((state: any) => state.stateDrawerNotify);

  const handleCloseDrawer = () => {
    dispatch({
      type: openNotifyTypes.OPEN_NOTIFY,
    });
  };

  return (
    <Drawer open={open} anchor={anchor} onClose={handleCloseDrawer}>
      <MyNotifications />
    </Drawer>
  );
};

export default MyDrawer;
