import {createActions, createReducer} from "reduxsauce";

enum statusEnum {
  success = "success",
  error = "error",
  warning = "waning",
  info = "info",
}

type initial = {
  isOpen: boolean;
  status: statusEnum | undefined;
  message: string;
};

const INITIAL_STATE: initial = {
  isOpen: false,
  status: undefined,
  message: "",
};

export const {Types, Creators} = createActions({
  changeAlert: ["isOpen", "status", "message"],
});

const changeAlert = (state = INITIAL_STATE, action: initial) => ({
  ...state,
  isOpen: action.isOpen,
  status: action.status,
  message: action.message,
});

export default createReducer(INITIAL_STATE, {
  [Types.CHANGE_ALERT]: changeAlert,
});
