import React from "react";
import {useSelector} from "react-redux";
import {Avatar} from "@mui/material";

const CustomAvatar: React.FC = ({children}) => {
  const {currentTheme} = useSelector((state: any) => state.stateTheme);

  return (
    <Avatar
      sx={{
        bgcolor: currentTheme === "light" ? "#6f2c91" : "#00b2af",
        width: "48px",
        height: "48px",
      }}>
      {children}
    </Avatar>
  );
};

export default CustomAvatar;
