import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Avatar,
  DialogActions,
  Button,
} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {Types as dialogTypes} from "store/reducers/dialogReducer";

import CheckIcon from "@mui/icons-material/Check";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";

const CustomDialog = (props: any) => {
  const dispatch = useDispatch();
  const {customOnClick, customOnClose} = props;
  const {isOpen, status, message} = useSelector(
    (state: any) => state.stateDialog,
  );
  const bgcolor = {
    success: "#4caf50",
    error: "#f44336",
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="xs"
      onClose={() => {
        dispatch({
          type: dialogTypes.CHANGE_DIALOG,
          isOpen: false,
          status: undefined,
          message: "",
        });
      }}>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <DialogContentText
          sx={{
            marginBottom: "16px",
            fontSize: "1.2rem",
            fontWeight: 500,
            color: status === "error" ? bgcolor.error : bgcolor.success,
          }}>
          {status === "error" ? "Ops! Algo deu errado" : "Sucesso!"}
        </DialogContentText>
        <Avatar
          sx={{
            marginBottom: "16px",
            bgcolor: status === "error" ? bgcolor.error : bgcolor.success,
            width: "80px",
            height: "80px",
          }}>
          {status === "error" ? (
            <PriorityHighOutlinedIcon sx={{fontSize: 60}} />
          ) : (
            <CheckIcon sx={{fontSize: 60}} />
          )}
        </Avatar>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}>
        {customOnClick && (
          <Button
            variant="contained"
            onClick={() => {
              customOnClick();
            }}
            autoFocus>
            Ok
          </Button>
        )}
        {customOnClose && (
          <Button
            variant="contained"
            onClick={() => {
              dispatch({
                type: dialogTypes.CHANGE_DIALOG,
                isOpen: false,
                status: undefined,
                message: "",
              });
            }}>
            Cancelar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(CustomDialog);
